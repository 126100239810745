<template>
  <div>
    <!-- 脚部 -->
    <div class="footer">
      <div class="top" style="display: none">
        <div class="wrapper">
          <!--          <div class="nav">-->
          <!--            <ul>-->
          <!--              <li>教评在线</li>-->
          <!--              <li>玉林校区</li>-->
          <!--              <li>学校文件</li>-->
          <!--              <li>教务在线</li>-->
          <!--              <li>图书馆</li>-->
          <!--              <li>人文医院</li>-->
          <!--              <li>门户邮件</li>-->
          <!--              <li>VPN</li>-->
          <!--              <li>心理咨询</li>-->
          <!--            </ul>-->
          <!--          </div>-->
          <!--          <div class="hots">-->
          <!--            <div class="content_h">-->
          <!--              <h3>友情链接:</h3>-->
          <!--              <ul>-->
          <!--                <li>-->
          <!--                  <select name="" id="">-->
          <!--                    <option value=" ">教育资源</option>-->
          <!--                      <option value=" ">教育资源</option>-->
          <!--                      <option value=" ">教育资源</option>-->
          <!--                      <option value=" ">教育资源</option>-->
          <!--                  </select>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <select name="" id="">-->
          <!--                    <option value=" ">兄弟院校</option>-->
          <!--                  </select>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <select name="" id="">-->
          <!--                    <option value=" ">学习考试</option>-->
          <!--                  </select>-->
          <!--                </li>-->
          <!--                <li>-->
          <!--                  <select name="" id="">-->
          <!--                    <option value=" ">知识社区</option>-->
          <!--                  </select>-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="bottom">
        <div class="wrapper">
          <div class="content_foot">
            <!--            <div class="left">-->
            <!--              <img src="@/assets/yxtx/footer-sydw.png" alt="">-->
            <!--            </div>-->
            <div class="right">
              <p class="right_p1">
                ©{{ newDate() }}河北百乐百货商业连锁有限公司
              </p>
              <div class="right_items">
                <div class="right_p2">
                  <img style="margin-right: 5px;" src="@/assets/yxtx/xwgk_ba.png">
                  <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #1a1818;">
                     网站备案号：冀ICP备11007430号-2</a>
                </div>
                <div>热线电话：400-118-6100</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /脚部 -->
  </div>
</template>

<script>
export default {
  methods: {
    newDate() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">

// 脚部样式
.footer {
  .top {
    background-color: #f5f5f5;
    height: 93px;

    .wrapper {
      .nav {
        ul {
          display: flex;
          padding-top: 25px;

          li {
            padding: 10px 15px 10px 15px;
            font-size: 12px;
            cursor: pointer;
            margin-top: 10px;
          }

          li:hover {
            background-color: #fff;
          }
        }
      }

      .hots {
        .content_h {
          display: flex;
          margin-left: 40px;

          h3 {
            font-size: 12px;
            color: #85360b;
            font-weight: lighter;
          }

          ul {
            display: flex;

            li {
              padding: 10px 15px 10px 15px;
              // width: 135%;
              select {
                border: 1px solid #ccc;
                background: no-repeat scroll right center #ffffff;
                color: #383030;
                font-size: 12px;
                width: 155px;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    height: 100px;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .content_foot {
        display: flex;
        text-align: center;
        //margin-left: 25%;
        .left {
          margin-top: 20px;
        }

        .right {
          //margin-left: 25px;
          margin-top: 20px;
          font-size: 12px;
          color: #1a1818;

          .right_p1 {
            margin: 0;

            span {
              margin-left: 25px;
            }
          }

          .right_items {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 8px;
            padding-bottom: 50px;
          }

          .right_p2 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 11px;
          }

          @media only screen and (max-width: 1000px) {
            .right_p2 {
              margin-right: unset;
              margin-bottom: 5px;
            }
          }
        }

        @media only screen and (max-width: 1000px) {
          .right {
            font-size: 14px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
