import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/Index"
  }, {
    path: '/Index',
    name: 'Index',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Index.vue')
  }, {
    path: '/home',
    name: 'Home',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Home.vue')
  }, {
    path: '/article',
    name: 'Article',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Article.vue')
  }, {
    path: '/errorPage',
    name: 'ErrorPage',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/errorPage.vue')
  }, {
    path: '/feedback',
    name: 'Feedback',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/feedback.vue')
  }, {
    path: '/Trade',
    name: 'Trade',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Trade.vue')
  }, {
    path: '/Shops',
    name: 'Shops',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/Shops.vue')
  }, {
    path: '/CateIndex',
    name: 'CateIndex',
    meta: {
      scrollToTop: true
    },
    component: () => import('../views/CateIndex.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
